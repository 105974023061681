<template>
  <section class="container mx-auto pt-24 pb-20">
    <div
      class="flex-wrap content-center animate__animated animate__fadeInDown animate__delay-2s"
    >
      <div class="text-center">
        <h2 class="text-3xl text-gray-900 mb-8">
          Kegiatan Program Pembelajaran<br />
          Bebras Indonesia
        </h2>
      </div>
    </div>
    <div v-if="loading">
      <img src="/loading.svg" class="mx-auto" alt="" />
    </div>

    <div v-if="!isData">
      <h1 class="text-center bg-orange-button shadow-lg rounded-20 my-20">
        Data Tidak Tersedia
      </h1>
    </div>
    <div>
      <div>
        <img
          id="image"
          :src="image + image_post"
          :class="[
            zoom
              ? 'animate__animated animate__fadeInRight'
              : 'animate__animated animate__fadeOutRight',

            hide ? 'hide' : '',
          ]"
          class="show_image absolute rounded-20"
          @click="showPostImage"
          alt=""
        />
      </div>
    </div>
    <div
      class="container my-12 mx-auto px-4 md:grid md:gap-4 md:grid-cols-3 md:px-12 my-30"
    >
      <div
        class="card-post flex flex-wrap -mx-1 lg:-mx-4 my-10"
        v-for="post in posts"
        :key="post.id"
      >
        <!-- Column -->
        <div class="my-1 px-1 w-full md:w-full lg:my-4 lg:px-4 lg:w-full">
          <!-- Article -->
          <article class="overflow-hidden rounded-lg shadow-lg">
            <img
              alt="Placeholder"
              class="block h-auto card-photo-custome"
              :src="image + post.image"
              :data-index="post.id"
              @click="showPostImage"
            />

            <header
              class="flex items-center justify-between leading-tight p-2 md:p-4 bg-white"
            >
              <h1 class="text-lg">
                <p class="no-underline text-black title-hide mb-3" href="#">
                  {{ post.title }}
                </p>
              </h1>
              <p class="text-grey-darker text-sm">
                {{ post.created_at }}
              </p>
            </header>

            <footer
              class="flex md:flex-col items-center justify-between leading-none p-2 md:p-4 bg-white"
            >
              <div class="flex no-underline text-black h-50">
                <img
                  alt="Placeholder"
                  class="block rounded-full"
                  :src="image + post.image"
                  style="width: 32px; height: 32px;"
                />
                <p class="ml-2 text-sm content-hide">
                  <span v-html="post.content"></span>
                </p>
              </div>
              <router-link
                :to="'/post/' + post.slug"
                class="bg-purple-hover p-4 md:my-7 no-underline text-white hover:bg-green-button rounded-full"
              >
                Selengkapnya
              </router-link>
            </footer>
          </article>
          <!-- END Article -->
        </div>
        <!-- END Column -->
      </div>
    </div>
  </section>
  <div class="md:flex md:justify-center p-5">
    <router-link
      to="/posts"
      class="mb-3 block mx-auto text-center w-full md:w-1/3 content-center md:mx-1 bg-orange-button hover:bg-green-button text-white font-semibold px-12 py-3 md:text-1xl rounded-full"
      :disabled="disabled"
    >
      Lihat semua Post
    </router-link>
  </div>
</template>

<style scoped>
.card-image {
  height: 804px;
  width: 800px;
}

.card-photo-custome {
  height: 350px;
  width: 100%;
  transition: all 0.5s ease-in-out;
  z-index: 0;
}

.card-photo-custome:hover {
  transform: scale(1.1);
  box-shadow: 0px 10px 31px -6px rgba(0, 0, 0, 0.75);
  -webkit-box-shadow: 0px 10px 31px -6px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 10px 31px -6px rgba(0, 0, 0, 0.75);
  transition: all 0.5s ease-in-out;
  cursor: pointer;
}

.show_image {
  width: 95%;
  height: 80%;
  margin-left: -5px;
  margin-top: 50px;
  z-index: 10;
}

.hide {
  display: none;
}

.title-hide {
  height: 30px;
  overflow: hidden;
}

.content-hide {
  height: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>

<script>
import axios from "axios";

export default {
  name: "CardSection",
  data() {
    return {
      posts: [],
      image: process.env.VUE_APP_IMAGE_URL,
      loading: true,
      isData: true,
      filter_text_post: [],
      image_post: "",
      zoom: false,
      hide: true,
    };
  },
  mounted() {
    this.getAllPosts();
  },
  methods: {
    async getAllPosts() {
      let uri = process.env.VUE_APP_API_ENDPOINT + "api/post";
      await axios
        .get(uri)
        .then((res) => {
          const { data } = res.data.data;
          const counter = data.length >= 3 ? 3 : data.length;
          if (counter != 0) {
            for (let index = 0; index < counter; index++) {
              if (data[index].created_at != null) {
                data[index].created_at = data[index].created_at
                  .split("-")
                  .join(" ");
                data[index].created_at = data[index].created_at
                  .split("")
                  .splice(0, 10)
                  .join("");
              }

              this.posts.push(data[index]);
            }
          } else {
            this.isData = false;
          }

          this.loading = false;
        })
        .catch((err) => console.log(err));
    },

    async showPostImage(event) {
      // toggle zoom image
      this.zoom = !this.zoom;
      if (this.zoom) this.hide = false;

      //filter index
      let index = event.target.getAttribute("data-index");
      let filter_photo = this.posts.filter((post) => {
        return post.id == index;
      });
      this.image_post = filter_photo[0].image;
    },
  },
};
</script>
