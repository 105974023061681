<template>
     <div class="cta-clip -mt-40"></div>
      <section class="call-to-action bg-red-footer pt-64 pb-10">
        <div class="container mx-auto">
          <div class="w-full text-center">
            <h1 class="text-3xl md:text-5xl text-white font-semibold">
              Ketahui lebih lanjut tentanng
              <br />
              Bebras Indonesia
            </h1>
            <router-link
              to="/posts"
              class="inline-block bg-orange-button hover:bg-green-button text-white font-semibold px-6 py-4 mt-8 text-lg rounded-full"
            >
             Ayo Mulai
            </router-link>
          </div>
        </div>
      </section>
      <footer class="bg-red-footer py-20 text-white text-lg">
        <div class="container mx-auto">
          <div class="sm:flex mb-4 mx-2">
            <div class="sm:w-1/2 sm:mt-0 mt-8 h-auto"> 
              <img src="/bebras.png" style="height: 100px" alt="" class="mb-3 lg:ml-10" />
              <p class="font-light">
                Bebras Indonesia 2021
              </p>
            </div>
            <div class="sm:w-1/4 h-auto">
              <div class="mb-8 font-bold">Tautan</div>
              <ul class="font-light">
                <li class="mb-3">Bebras Internasional</li>
                <li class="mb-3">Kementrian Pendidikan</li>
                <li class="mb-3">Bebras Daftar 2021</li>
                <li class="mb-3">Bebras Indonesia</li>
              </ul>
            </div>
            <div class="sm:w-1/4 h-auto">
              <div class="mb-8 font-bold">Arsip</div>
              <ul class="font-light">
                <li class="mb-3">Bebras Challenge</li>
                <li class="mb-3">Road to computational Thinking</li>
                <li class="mb-3">Tutorials Bebras</li>
                <li class="mb-3">Challenge 2021</li>
              </ul>
            </div>
            <div class="sm:w-1/4 h-auto">
              <div class="mb-8 font-bold">Kontak</div>
              <ul class="font-light">
                <li class="mb-3">+629999999</li>
                <li class="mb-3">NTB, Mataram</li>
                <li class="mb-3">No.10 Udayana</li>
                <li class="mb-3">bebras@bebras.co.id</li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
</template>


<script>
export default {
    name:'FooterSection',
}
</script>