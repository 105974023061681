<template>
  <section
    class="container mx-auto pt-24 pb-20  animate__animated animate__fadeInUp animate__delay-4s"
  >
    <div class="campaign-text">
      <h2 class="text-center lg:ml-20 text-3xl text-gray-900 mb-10">
        Kegiatan <br />
        Bebras Indonesia
      </h2>
    </div>
    <div
      class="campaign-point md:flex justify-center mt-3"
      data-aos="fade-down"
      data-aos-duration="1000"
    >
      <div class="campaign flex flex-col items-center">
        <div class="mx-5 w-1/4 my-4 p-5 border border-gray-500 rounded-20">
          <img src="/campaign-1.png" class="w-auto" alt="campaign-item" />
        </div>
        <div class="campaign-desc p-5">
          <p class="text-center text-1xl text-gray-900 mb-8 w-auto">
            Workshop/lokakarya dilaksanakan oleh Bebras Biro untuk memberi bekal
            kepada guru agar para guru mampu memperkenalkan konsep berpikir
            komputasi atau informatika
          </p>
        </div>
      </div>

      <div class="campaign flex flex-col items-center">
        <div class="mx-5 w-1/4 my-4 p-5 border border-gray-500 rounded-20">
          <img src="/campaign-2.png" class="w-auto" alt="campaign-item" />
        </div>
        <div class="campaign-desc p-5">
          <p class="text-center text-1xl text-gray-900 mb-8 w-auto">
            Berlangsung sekali setahun untuk koordinasi komite nasional (NBO
            Bebras Indonesia) dengan mitra (Bebras Biro), dan menetapkan
            soal-soal nasional.
          </p>
        </div>
      </div>

      <div class="campaign flex flex-col items-center">
        <div class="mx-5 w-1/4 my-4 p-5 border border-gray-500 rounded-20">
          <img src="/campaign-3.png" class="w-auto" alt="campaign-item" />
        </div>
        <div class="campaign-desc p-5">
          <p class="text-center text-1xl text-gray-900 mb-8 w-auto">
            Diselenggarakan sesuai jadwal yang ditetapkan komite internasional,
            biasanya minggu kedua atau ketiga November (disebut Bebras Week)
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "CampaignSection",
};
</script>
